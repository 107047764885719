<template>
  <div class="first" style="min-height: 100vh; height: 100%;">
    <div class="header">
      <van-nav-bar>
        <template #left>
          <van-image
            width="2rem"
            height="2rem"
            :src="appLogo"
            @click="openside"
          />
        </template>
        <template #right>
          <van-dropdown-menu active-color="#333">
            <van-dropdown-item
              v-model="lang"
              :options="option"
              @change="change"
            />
          </van-dropdown-menu>
        </template>
        <!-- <template #title>
          <span>{{ appName }}</span>
        </template> -->
      </van-nav-bar>
    </div>
    <div class="maincontent">
      <!-- 轮播图 -->
      <div class="swipe">
        <van-swipe class="first-swipe" :autoplay="3000">
          <van-swipe-item
            v-for="item in bannerList"
            :key="item.id"
            @click="onBannerClick(item)"
          >
            <img :src="item.imageurl" />
          </van-swipe-item>
          <template #indicator>
            <span></span>
          </template>
        </van-swipe>
      </div>
      <!-- 通知栏 -->
      <van-notice-bar scrollable left-icon="volume-o" :text="indexDataNotice" />

      <van-tabs
        color="#f0b82d"
        title-active-color="#f0b82d"
        @click="onTabClick"
      >
        <van-tab
          :title="item.name"
          v-for="(item, index) in tabList"
          :key="index"
        >
          <van-list v-model="loading" :finished="true">
            <div
              @click="onItemClick(item)"
              class="itemV"
              v-for="(item, index) in list1"
              :key="index"
            >
              <div class="itemLeft">
                <span class="itemTv1">{{ item.title }}</span>
                <span class="itemTv2">{{ item.desc }}</span>
              </div>
              <img class="itemIv" :src="item.image" />
            </div>
          </van-list>
        </van-tab>
      </van-tabs>

      <!-- 修改密码弹窗 -->
      <van-popup
        v-model="suiji"
        position="center"
        :close-on-click-overlay="false"
        style="width:80%;border-radius:8px; font-size:16px"
      >
        <h3 style="line-height:40px">{{ $t("editpass.title") }}</h3>
        <van-cell-group>
          <van-field
            v-model="editpass.oldpass"
            center
            clearable
            type="password"
            :placeholder="$t('register.enterpassword')"
          />
          <van-field
            v-model="editpass.newpass"
            center
            clearable
            type="password"
            :placeholder="$t('qing-shu-ru-xin-mi-ma')"
          />
          <van-field
            v-model="editpass.newpass2"
            center
            clearable
            type="password"
            :placeholder="$t('qing-shu-ru-xin-mi-ma')"
          />
        </van-cell-group>
        <van-button style="width:100%" @click="passsubmit">{{
          $t("common.confirm")
        }}</van-button>
      </van-popup>
    </div>
    <audio controls ref="notify" style="display:none">
      <source src="msg.mp3" />
    </audio>
    <!--    <van-notify //消息提示
      v-model="notifyshow"
      @click.native="$router.push('/trade/contact/list')">
      <span>{{$t('ni-you') }}{{ msgcount }}{{ $t('tiao-xin-xiao-xi') }}</span>
      <van-icon
        name="cross"
        style="position:absolute;right:1rem;"
        @click.stop="closenotify"
      />
    </van-notify> -->
  </div>
</template>
<script>
function setCookie(cname, cvalue, exdays) {
  var d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  var expires = "expires=" + d.toUTCString();
  document.cookie = cname + "=" + cvalue + "; " + expires;
}
//定义一个函数判断是手机端还是pc端
function isMobile() {
  if (
    window.navigator.userAgent.match(
      /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
    )
  ) {
    return true; // 移动端
  } else {
    return false; // PC端
  }
}
setCookie("googtrans", "/auto/en", 100);
export default {
  data() {
    return {
      selectLang: "",
      lang: localStorage.getItem("lang") || "en",
      option: [
        { text: "English", value: "en" },
        { text: "繁体中文", value: "ru" }, //繁体中文
        { text: "اللغة العربية", value: "zh" }, //阿拉伯语
        { text: "日本語", value: "fr" },
        { text: "Tiếng Việt", value: "it" }, //越南语
        { text: "Español", value: "es" }, //西班牙语
      ],
      popshow: false,
      indexData: {
        notice: [""],
      },
      indexDataNotice: "",
      product: [],
      serverurl: "",
      user: {},
      suiji: false,
      editpass: {
        oldpass: "",
        newpass: "",
        newpass2: "",
      },
      msgcount: 0,
      notifyshow: false,
      websocket: null,
      wk: null,
      appLogo: "",
      appName: "",
      bannerList: [],
      tabList: [],
      list1: [],
      loading: false,
    };
  },
  created() {
    // // 判断后的操作
    // if (!isMobile()) {
    //   this.$router.push("/web/index/index");
    //   return;
    // }
    this.getdata();
    this.getmsgcount();
    this.getLogo();
    this.getBanner();
    this.getNewsCate();

    // alert(this.lang)
  },
  mounted() {
    this.initwk();
  },
  methods: {
    // 切换语言
    change(val) {
      this.$i18n.locale = val;
      localStorage.setItem("lang", val);
    },
    async getNewsCate() {
      const { data } = await this.$http.get("/home/news/getNewsCate");
      if (data) {
        if (data.code === 200) {
          this.tabList = data.data;
          this.getzxlist(this.tabList[0].id);
        }
      }
    },
    async getzxlist(id) {
      const { data } = await this.$http.get("/home/news/newslist", {
        params: { cate_id: id },
      });
      if (data) {
        if (data.code === 200) {
          this.list1 = data.data;
        }
      }
    },
    //获取应用名称logo
    async getLogo() {
      
      const { data } = await this.$http.get("/home/index/getConf");
      if (data) {
        if (data.code === 200) {
          this.appLogo = data.data.imageurl;
          this.appName = data.data.sitename;
        }
      }
    },
    //获取首页轮播图
    async getBanner() {
      const { data } = await this.$http.get("/home/index/banner");
      if (data) {
        if (data.code === 200) {
          this.bannerList = data.data;
        }
      }
    },
    //轮播图点击
    onBannerClick(item) {
      // window.location.href = item.jumpurl;
    },
    // 获取未读信息数
    async getmsgcount() {
      const { data } = await this.$http.get("/home/home/msgcount");
      if (data) {
        if (data.code === 200) {
          this.msgcount = +data.data;
          if (this.msgcount > 0) {
            this.notifyshow = true;
            // this.$refs.notify.play();
          }
        }
      }
    },
    // 获取首页数据
    async getdata() {
      const { data } = await this.$http.get("/home/home/index");
      if (data) {
        if (data.code === 200) {
          this.indexData = data.data;

          if (localStorage.getItem("lang") == "en") {
            this.indexDataNotice = data.data.notice_en[0];
          } else if (localStorage.getItem("lang") == "ru") {
            this.indexDataNotice = data.data.notice_ru[0];
          } else if (localStorage.getItem("lang") == "zh") {
            this.indexDataNotice = data.data.notice[0];
          } else if (localStorage.getItem("lang") == "fr") {
            this.indexDataNotice = data.data.notice_fr[0];
          } else if (localStorage.getItem("lang") == "it") {
            this.indexDataNotice = data.data.notice_it[0];
          } else {
            this.indexDataNotice = data.data.notice_es[0];
          }

          this.product = data.data.product;
          this.serverurl = data.data.serverurl;
          this.user = data.data.user;
          if (this.websocket === null) {
            this.initwebsocket();
          }
          // if (data.data.user.random_password === "1") {
          //   this.suiji = true;
          // }
        }
      }
    },
    onTabClick(pos) {
      this.loading = true;
      var item = this.tabList[pos];
      this.getzxlist(item.id);
      this.loading = false;
    },
    openside() {
      // this.popshow = true;
    },
    // 去教程
    tocourse() {
      this.$router.push("/course");
    },
    // // 去理财页
    // tofund() {
    //   this.$router.push("/fund");
    // },
    onCardClick() {
      this.$router.push({ path: "/assets/card/" + 1 });
    },
    // 去设置页
    tosetting() {
      this.$router.push("/person/setting");
    },
  
    // 退出
    logout() {
      window.localStorage.removeItem("token");
      this.$router.push("/login");
    },
    //详情
    onItemClick(item) {
      this.$router.push({ path: "/news/detail/" + item.id });
    },
    async passsubmit() {
      if (
        !this.editpass.oldpass ||
        !this.editpass.newpass ||
        !this.editpass.newpass2
      ) {
        this.$toast.fail(this.$t("editpass.notice3"));
        return false;
      }
      if (this.editpass.newpass !== this.editpass.newpass2) {
        this.$toast.fail(this.$t("editpass.eqpass"));
        return false;
      }
      const { data } = await this.$http.post(
        "/home/home/editpass",
        this.editpass
      );
      if (data) {
        if (data.code === 200) {
          this.suiji = false;
          this.getdata();
        } else {
          this.$toast.fail(
            this.getlang(data.msg, localStorage.getItem("lang"))
          );
        }
      }
    },
    selectLangChange(value) {
      this.$i18n.locale = value;
      localStorage.setItem("lang", value);
      this.getdata();
    },
    // 切换语言
    // Language() {
    //   // function setCookie(cname, cvalue, exdays) {
    //   //   var d = new Date()
    //   //   d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000)
    //   //   var expires = 'expires=' + d.toUTCString()
    //   //   document.cookie = cname + '=' + cvalue + '; ' + expires
    //   // }
    //   // setCookie('googtrans', '', -1111111111111)
    //   setCookie("googtrans", "/auto/en", 100);
    //   if (this.$i18n.locale === "zh") {
    //     this.$i18n.locale = "en";
    //     localStorage.setItem("lang", "en");
    //     // setCookie('googtrans', '/auto/en', 100)
    //   } else {
    //     this.$i18n.locale = "zh";
    //     localStorage.setItem("lang", "zh");
    //     // setCookie('googtrans', '/auto/zh', 100)
    //   }
    //   window.location.reload();
    // },
    // 初始化连接
    initwebsocket() {
      this.websocket = new WebSocket(this.wsurl + "/wss:8880");
      this.websocket.onmessage = this.websocketonmessage;
      this.websocket.onerror = this.websocketonerror;
    },
    // 连接错误
    websocketonerror(e) {
      this.initwebsocket();
    },
    // 接收到数据
    websocketonmessage(e) {
      const message = JSON.parse(e.data);
      switch (message.type) {
        case "init":
          this.binduid();
          break;
        case "text":
          this.getmsgcount();
          break;
        case "image":
          this.getmsgcount();
          break;
      }
    },
    // 绑定uid
    binduid() {
      const msg = { type: "bind", userid: this.user.id };
      this.websocketsend(JSON.stringify(msg));
    },
    // websorcket发送信息
    websocketsend(data) {
      this.websocket.send(data);
    },
    closenotify() {
      this.notifyshow = false;
    },
    initwk() {
      // this.wk = new WebSocket(this.wsurl + "/wssss:8080");
      // this.wk.onmessage = this.wkonmessage;
    },
    wkonmessage(e) {
      try {
        const data = JSON.parse(e.data);
        
        // console.log('行情:', data)
        // console.log(this.product)
        // for (let key in data) {
        //   data[key].pid = this.product[key].pid;
        //   data[key].logo = this.product[key].logo;
        // }
        data.map((item) => {
          this.product.forEach((p) => {
            if (p.product_title === item.product_title) {
              p.change = item.change;
              p.current = item.current;
            }
          });
        });
        // this.product = data;
      } catch (error) {
        
      }
    },
  },
};
</script>
<style lang="less" scoped>
.itemV {
  display: flex;
  border-radius: 10px;
  margin: 10px;
  background-color: #fff;
  padding: 12px;
  box-sizing: border-box;
  .itemLeft {
    flex: 1;
    display: flex;
    flex-direction: column;
    .itemTv1 {
      font-weight: 400;
      color: #222732;
      font-size: rem;
      width: 100%;
      display: flex;
      text-align: left;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
    }
    .itemTv2 {
      display: flex;
      justify-content: flex-start;
      color: #858a8f;
      width: 100%;
      margin-top: 5px;
      text-align: left;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
    }
  }
  .itemIv {
    border-radius: 10px;
    width: 145px;
    height: 80px;
    margin-left: 5px;
  }
}
.header {
  width: 100%;
  /deep/.van-icon {
    color: #b2b2b2;
  }
  /deep/.van-nav-bar__right {
    font-size: 1.5rem;
  }
  .van-info {
    min-width: 18px;
    font-size: 14px;
  }
}
.maincontent {
  padding: 50px 0;
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  font-size: 0.93333rem;
  // 轮播图
  .first-swipe {
    margin-top: 0.5rem;
    height: 10rem;
    align-items: center;
    justify-content: center;
    .van-swipe-item img {
      width: 94%;
      height: 10rem;
      border-radius: 5px;
    }
  }
  .van-notice-bar {
    width: 100%;
    background-color: #fff00000;
    color: #212121;
    border-radius: 5px;
  }
  //三个产品
  .currency-information {
    margin: 0.53333rem 0.8rem 0.53333rem 0.8rem;
    line-height: 2.66667rem;
    font-size: 0.93333rem;
    display: flex;
    white-space: nowrap;
    overflow-x: auto;
    .information {
      flex-shrink: 0;
      margin-right: 0.53333rem;
      width: 10.6rem;
      height: 4.26667rem;
      padding: 0.4rem;
      background-color: #fff;
      line-height: 1.6rem;
      border-radius: 0.26667rem;
    }
  }
  // 快捷买币
  .currency {
    width: 94%;
    margin-left: 3%;
    background-color: #fff;
    border-radius: 0.26667rem;
    overflow: hidden;
    .currency-shopping {
      background-color: #fff;
      color: #000;
      line-height: 1.46667rem;
      padding: 0.4rem;
      display: flex;
      align-items: center;
      justify-content: center;
      border-top-left-radius: 0.26667rem;
      border-top-right-radius: 0.26667rem;
      .currency-shopping-image {
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .currency-shopping-left {
        font-size: 0.9333rem;
        text-align: left;
        padding-left: 0.8rem;
        span {
          font-size: 0.61333rem;
        }
      }
    }
    .currency-service-span {
      border-bottom-left-radius: 0.26667rem;
      border-bottom-right-radius: 0.26667rem;

      div {
        padding: 0.4rem;
        background-color: #fff;
        display: flex;
        align-items: center;
        text-align: left;
        .van-image {
          padding: 0;
        }
        .van-col {
          float: left;
          box-sizing: border-box;
          min-height: 1px;
        }
        span {
          font-size: 0.8rem;
          margin-left: 0.53333rem;
        }
      }
    }
  }
  // banner
  .banner-image {
    width: 94%;
    margin: 0.53333rem 3%;
    .van-image {
      position: relative;
      display: inline-block;
    }
    /deep/.van-image__img {
      display: block;
      width: 100%;
      height: 100%;
      border-radius: 0.53333rem !important;
    }
  }
  // 产品列表
  .currency-title {
    margin-top: 0.8rem;
    margin-bottom: 0.4rem;
    font-size: 0.8rem;
    color: #b2b2b2;
  }
  .currency-Increase {
    background-color: #fff;
    padding-top: 0.8rem;
    padding-bottom: 0.8rem;
    font-size: 1.12rem;
    height: 3.46667rem;
    border-bottom: 0.02667rem solid #e5e5e5;
    display: flex;
    align-items: center;
    .currency-btn {
      border: none;
      border-radius: 0.26667rem;
      width: 6.4rem;
      height: 2.13333rem;
      color: #fff;
      font-size: 1.12rem;
    }
  }
}
// 弹出层
.personage-head {
  margin-top: 3.75rem;
  display: flex;
  align-items: center;
  padding-left: 1rem;
  .personage-head-image {
    margin-right: 5%;
  }
  .personage-head-span {
    text-align: left;
    font-size: 1.33333rem;
    line-height: 2.13333rem;
  }
}
.personage-member {
  height: 6.31rem;
  margin-top: 1.12rem;
  display: flex;
  align-items: center;
  justify-content: center;
  .personage-member-a {
    width: 100%;
    height: 100%;
    padding: 0 5%;
    margin-left: 1rem;
    margin-right: 1rem;
    border-radius: 1rem;
    background-image: url("../../assets/img/vip_bg.png");
    background-size: 100% 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .personage-member-p1 {
      font-family: "YouSheBiaoTiHei";
      font-style: normal;
      font-weight: 900;
      font-size: 20px;
      line-height: 26px;
      letter-spacing: -0.165px;
      background: linear-gradient(270deg, #fce9b8 0%, #f4cb85 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      font-style: italic;
      letter-spacing: 0.1em;
      text-align: left;
    }
    .personage-member-p2 {
      font-family: "Alibaba PuHuiTi";
      font-style: normal;
      font-weight: 400;
      font-size: 10px;
      line-height: 14px;
      letter-spacing: -0.165px;
      background: linear-gradient(
        270deg,
        rgba(252, 233, 184, 0.85) 0%,
        rgba(244, 203, 133, 0.85) 100%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-align: left;
    }
    .personage-member-p3V {
      margin-top: 0.56rem;
      display: flex;
      justify-content: flex-end;
      .personage-member-p3 {
        background: linear-gradient(270deg, #fae6ad 0%, #fff2c7 100%);
        border-radius: 12.5px;
        color: #333;
        font-size: 0.68rem;
        height: 1.56rem;
        padding-left: 0.87rem;
        padding-right: 0.87rem;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: "Alibaba PuHuiTi";
        font-style: normal;
        font-weight: 400;
      }
    }
  }
  .van-col {
    float: left;
    box-sizing: border-box;
    min-height: 1px;
  }
}
// 列表区域
.van-cell {
  font-size: 1.06667rem;
  color: #333 !important;
  height: 3.2rem;
  font-family: "Alibaba PuHuiTi";
  font-style: normal;
  font-weight: 400;
}
.van-popup .van-icon {
  font-size: 1.2rem;
}
.van-cell__title {
  text-align: left;
  margin-left: 0.4rem;
}
// 切换语言
.personage-cut {
  width: 100%;
  position: fixed;
  bottom: 4rem;
  background-color: #f7f7f7;
  height: 2.66667rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.98667rem;
  color: #004ea3;
}
// 退出登录
.personage-button {
  width: 100%;
  position: fixed;
  bottom: 0.63333rem;

  .logoutV {
    padding-top: 1rem;
    padding-bottom: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    .logoutIv {
      width: 1rem;
      height: 1rem;
    }
    .logoutTv {
      margin-left: 5px;
      color: #333;
      font-size: 0.93;
    }
  }
}
/deep/.van-tab--active {
  color: #f0b82d !important;
}
/deep/.van-dropdown-menu__bar {
  background: #00000000 !important;
  box-shadow: 0 2px 12px rgba(100,101,102,0);
}
.van-dropdown-menu {
  /deep/.van-dropdown-menu__title {
    color: #333;
  }
}
</style>
